@import "./variables.scss";

.wrapper-select {
  min-height: 52px;
  margin: 16px 0;
  & > div {
    background: $white;
    position: relative;
  }
  & > .error {
    margin-bottom: 0;
  }
  label {
    display: block;
    width: calc(100% - 64px);
    height: 18px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $form-label-color;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    top: 6px;
    left: 16px;
    margin: 0 !important;
    padding: 0;
    z-index: 1;
  }
  .wrapper_select_single {
    &.empty + label {
      top: 14px;
    }
    &.empty {
      .react-select_single__control > .react-select_single__value-container .react-select_single__placeholder {
        color: transparent;
      }
    }
    .react-select_single__control > .react-select_single__value-container .react-select_single__placeholder {
      color: rgba($form-text-color, 0.5);
    }
    & > .react-select_single__control {
      background: transparent;
      position: relative;
    }
  }
}

.wrapper_select_single {
  width: 100%;
  min-height: 36px;
  margin: 0;
  padding: 0;

  .react-select_single__control {
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: 36px;
    border-radius: 4px 4px 0 0;
    outline: none;
    z-index: 2;

    border-bottom-color: $form-border-color;
    border-style: none none solid none;
    border-width: 0 0 1px 0;

    background: $white;
    position: relative;

    &:hover {
      border-bottom-color: $form-border-color;
    }

    > .react-select_single__value-container {
      flex-wrap: wrap;
      padding: 26px 16px 6px;
      overflow: hidden;
      margin: 0;
      height: 52px;
      align-items: stretch;

      .react-select_single__input-container {
        flex: 1 1 auto;
        padding: 0 2px;
        min-height: 20px;
        margin: 5px 0;
      }
      .react-select_single__placeholder {
        margin: 0;
      }
      .react-select_single__multi-value,
      .react-select_single__value {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        width: auto;
        position: relative;
        padding: 0 24px 0 4px;
        background: $shadow-table-expanded;
        margin: 2px;
        z-index: 2;
        > .react-select_single__multi-value__label,
        > .react-select_single__value__label {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $black;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0;
          margin: 0;
        }
      }
    }

    > .react-select_single__indicators {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      width: auto;
      max-width: 65px;
      margin: 0;

      .loader-wrapper {
        margin: 0;
        padding: 0;
        order: 1;
        flex: 0 1 auto;
        align-self: auto;
      }

      .react-select_single__indicator-separator {
        display: none;
      }

      .react-select_single__dropdown-indicator {
        position: relative;
        width: 64px;
        height: 52px;
        margin: 0;
        padding: 0;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          margin: -12px 0 0 -12px;
          padding: 0;
          top: 50%;
          left: 50%;
          background: transparent url("../svg/toggle-select.svg") no-repeat scroll 0 0;
          background-size: 24px 48px;
        }
        > svg {
          display: none;
        }
        order: 2;
        flex: 0 1 auto;
        align-self: auto;
        cursor: pointer;
      }
    }

    &--is-focused {
      border-bottom-color: $form-border-color-active;
      box-shadow: inset 0px -1px 0px $form-border-color-active;
      > .react-select_single__indicators {
        .react-select_single__clear-indicator {
          background-position: 0 -24px;
        }

        .react-select_single__indicator-separator {
          &::before {
            background: $black;
          }
        }
      }
    }

    &--menu-is-open {
      & + label {
        top: 6px;
      }
      &:hover {
        border-color: none;
      }
      > .react-select_single__indicators {
        .react-select_single__dropdown-indicator {
          &::before {
            background-position: 0 -24px;
          }
        }
      }
    }
  }

  &.react-select_single--is-disabled {
    > .react-select_single__control {
      background:  $form-bg-disabled;
    }
  }

  &.error {
    margin-bottom: 0;
    .react-select_single__control {
      border-bottom-color: $red-600 !important;

      &.react-select_single__control--is-focused {
        border-bottom-color: $red-600 !important;
        box-shadow: inset 0px -1px 0px $red-600 !important;
      }
    }
  }
}

.react-select_single__menu {
  background: $white;
  border: 1px solid $gray-200;
  box-shadow: 0px 6px 24px -6px rgba($black, 0.2);
  border-radius: 4px;
  margin: 0;
  padding: 0;
  z-index: 100 !important;

  .react-select_single__menu-list {
    padding: 0;
    margin: 0;
    .react-select_single__option {
      padding: 8px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      color: $black;
      position: relative;
      cursor: pointer;
      min-height: 36px;
      margin: 0;
    }
    .react-select_single__option--is-focused {
      background-color: transparent;
    }
    .react-select_single__option:hover {
      background-color: $gray-100;
    }
    .react-select_single__option--is-selected {
      background-color: $gray-100;
      color: $black;
    }
    .react-select_single__group {
      padding: 0;
      margin: 0;
      .react-select_single__group-heading {
        padding: 8px 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        text-transform: none;
        margin: 0;
        white-space: normal;
        word-break: break-all;
        border-bottom: 1px solid $gray-200;
      }
      .react-select_single__group-heading + div {
        .react-select_single__option {
          padding-left: 32px;
        }
      }
    }
  }

  .flat-virtualized-item {
    height: 36px !important;
    .fast-option {
      padding: 0 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 36px !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $black;
      position: relative;
      cursor: pointer;
    }
    .fast-option.fast-option-focused {
      background-color: $gray-100;
    }
    .fast-option.fast-option-selected {
      background-color: $gray-100;
      color: $black;
    }
  }
}

.react-select_multiple__menu-portal,
.react-select_single__menu-portal {
  margin: 0 !important;
  z-index: 1060 !important;
}

.ref-select {
  position: relative;
  z-index: 1060 !important;
}

@import "./variables.scss";

.loader-wrap {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  & > .loader {
    width: 80px;
    height: 80px;
    border: 6px solid $gray;
    border-bottom-color: $blue;
    border-radius: 50%;
    margin-bottom: 10px;
    animation: rotation 3s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  & > .title {
    color: #1f2125;
    display: inline-block;
    position: relative;
    font-size: 14px;
    &::after {
      content: "";
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: currentColor;
      position: absolute;
      bottom: 6px;
      right: 18px;
      animation: anim-dash 1s linear infinite;
      @keyframes anim-dash {
        0% {
          box-shadow:
            6px 0 rgba($white, 0),
            12px 0 rgba($white, 0);
        }
        50% {
          box-shadow:
            6px 0 #838388,
            12px 0 rgba($white, 0);
        }
        100% {
          box-shadow:
            6px 0 $dark-grey,
            12px 0 $dark-grey;
        }
      }
    }
  }
}

.references-box {
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 16px 0;
    font-size: 14px;
    line-height: 1.4;

    &:first-child {
      margin-top: 0;
    }
  
    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: #0f62fe;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

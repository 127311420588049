@import "./variables.scss";

.wrapper-maintenance-page {
  width: 100%;
  min-height: 100vh;

  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;

  > div {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;
    width: 100%;
    max-width: 420px;
    padding: 16px;
    margin: 0;
    text-align: center;

    p {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      font-weight: 400;
      overflow: hidden;
      margin: 16px 0;
      text-align: left;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .logo {
      display: block;
      width: 100%;
      max-width: 220px;
      margin: 32px 0;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      > span {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0 0 13.63636363636364%;
        background: transparent url("../svg/logo.svg") no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }
  }
}